



























































































































































  .table-premios::v-deep {
    .v-data-table__expanded__content {
      box-shadow: none;
      padding: 60px;
      background: #f1f1f1;
    }
    .table-premios-td {
      padding: 10px;
      .v-data-table__wrapper {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
      }
    }
  }

  .cartela-bingo {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 260px;
  }

  .v-application::v-deep .v-small-dialog__menu-content {
    min-width: 330px !important;
  }

.cartela {
  &-margin {
    margin: 5px;
  }
  &-head-numero {
    padding-top: 10px;
    float: left;
    padding-top: 8px;
    display: block;
  }
  &-content {
    width: calc(100% / 3);
    @media (max-width: 1190px) {
      width: calc(100% / 3);
    }
    @media (max-width: 900px) {
      width: calc(100% / 2);
    }
    @media (max-width: 500px) {
      width: 100%;
    }
    display: inline-block;
    float: left;
  }
  &-init {
    overflow: auto;
    width: 100%;
    padding: 5px;
  }
  // &-ganhou{
  //   &-0{
  //     background-image: linear-gradient(to top, #800101, #f50e0e) !important;
  //   }
  //   &-1{
  //     background-image: linear-gradient(to top, #550aa1, #a10ce1) !important;
  //   }
  //   &-2{
  //     background-image: linear-gradient(to top, #0c9a00, #9adf07) !important;
  //   }
  //   &-3{
  //     background-image: linear-gradient(to top, #d66100, #ffde00) !important;
  //   }
  //   &-4{
  //     background-image: linear-gradient(to bottom, #00bff1, #003ab5) !important;
  //   }
  // }
  &-head {
    display: flex;
    &-letter {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      width: 100%;
      color: white;
      background-image: linear-gradient(to top, #800101, #f50e0e);
      &-1 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #800101, #f50e0e);
      }
      &-2 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #550aa1, #a10ce1);
      }
      &-3 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #0c9a00, #9adf07);
      }
      &-4 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #d66100, #ffde00);
      }
      &-5 {
        // @extend %default-head;
        background-image: linear-gradient(to bottom, #00bff1, #003ab5);
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        left: 5;
        transform: translate(-50%, -50%);
      }
    }
    &-default {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      width: 100%;
      height: 33px;
      color: #4b4b4b;
      font-weight: 600;
      background: white;
      font-size: 16px;
      border: 1px solid #4b4b4b;

      @media (max-width: 769px) {
        font-size: 12px;
      }

      input {
        display: none;
      }
      input:checked + label {
        color: white;
        font-weight: bold;
        display: block;
        width: 100%;
        position: relative;
        z-index: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding-bottom: 28px;
          border-radius: 50%;
          width: 28px;
          position: absolute;
          background: #070988;
          z-index: -1;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &-content-linha {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
  }
  // &-linha-fechada{
  //   background: #a0a0a0 !important;
  // }
  &-linha {
    width: 100%;
    display: flex;
    width: 100%;
  }
  &-numeros {
    background: white;
    position: relative;
    span {
      font-weight: bold;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      width: 100%;
    }
  }
  /* Marca cartelas durante o sorteio */
  &-fechada {
    // @extend %cartelas-marcadas;
  }
  &-disable {
    .cartela-head-default {
      background-image: linear-gradient(to bottom, #fafafa, #666) !important;
      input:checked + label {
        color: #d1d1d1;
      }
      input:checked + label {
        color: #d1d1d1;
      }
      input:checked + label span {
        background: #686868;
      }
    }
  }
  &-ganhou-linha {
    // @extend %cartelas-marcadas;
  }
  /* Animação das 5 primeiras bolas */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

